import Layout from "components/layout";
import SEO from "components/seo";
import React from "react";
import Atlanta from "components/locationIndexPages/atlanta/index";

function AtlantaLocation() {
  const title = "Atlanta Translation Services - Translation Agency";
  const description =
    "Looking for translation services in Atlanta? Tomedes provides translation, localization, and interpretation solutions in 120+ languages with rapid delivery and 24/7 support.";
  const keywords = "Translation Services in Atlanta";

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/atlanta-translation-services"
      />
      <Atlanta />
    </Layout>
  );
}

export default AtlantaLocation;
