export const atlantaData = [
  {
    title: "For Immigration",
    detail:
      "Atlanta has a large immigrant population. Tomedes provides translation services for families whose home language is other than English. If you’re looking to translate your birth certificates, financial documents, and other necessary application documents for USCIS or any other institutions, we’ve got you covered..",
    link: "/uscis-translation-services",
  },
  {
    title: "University Applications",
    detail:
      "If you’re in the various universities in Atlanta, or applying to universities abroad at any stage of your education, translation services in Atlanta, GA should be your go to. We can translate any of your application’s documents, such as your transcript, your academic references, and any of your academic records.",
    link: "/transcript-translation-services",
  },
  {
    title: "Legal Proceedings",
    detail:
      "The most elite translation services in Atlanta must provide options for translation such as in the legal field. At Tomedes, a legal translator will always translate your affidavits, amending agreements, articles of incorporation, and more. Our legal translators go through a rigorous process to make sure your language translations can be held up in a court of law.",
    link: "/legal-translation.php",
  },
];

export const LanguagesInAtlanta = [
  {
    heading: "Spanish",
    detail:
      "Spanish is spoken by the majority of Atlanta's population, after English. Spanish translation services are available for businesses and persons looking to transform their websites, documents, and apps from any other language into Spanish. If this is the ideal situation for you, reach out to Tomedes.",
    link: "/spanish-translation.php",
  },
  {
    heading: "French",
    detail:
      "Another popular language in Atlanta, French is also a major language in the world. For those looking to translate from English to French, French to English, or any other language pairs, there's many to choose from at Tomedes. If you're looking to expand your global footprint, get in touch now.",
    link: "/languages/french",
  },
  {
    heading: "Japanese",
    detail:
      "Japanese is also a major language spoken by Atlanta residents, and is one of the most used business languages in the world. Whether you're translating from Japanese to English and English to Japanese, do you want to interest this demographic in Atlanta or spread your horizons to worldwide Japanese consumers. If so, contact Tomedes.",
    link: "/languages/japanese",
  },
  {
    heading: "Chinese",
    detail:
      "After Spanish, Chinese is the most spoken language in Atlanta, according to statistical data. Atlanta has a Chinese population of 3,500 residents. Aside from that, Chinese is also a major business language in the world, and the country's GDP is the second largest in the world. If you're looking to appeal to this demographic, or go beyond Atlanta, contact us.",
    link: "/mandarin-chinese-translation",
  },
];
